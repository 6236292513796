@charset "UTF-8";
@import 'https://cdn.mfitpersonal.com.br/fa/6.6/css/all.min.css';
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

.reapop__notification--info{
  background-color: #E0EFFE !important;
}

.reapop__notification--success{
  background-color: #DBFDEB !important;
  color: #172331 !important;
}
.reapop__notification--warning{
  background-color: #FDFAC8 !important;
}
.reapop__notification--error{
  background-color: #FFDEE0 !important;
}
